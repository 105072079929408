var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-manager-common-edit-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c("el-form-item", { attrs: { label: "通用类别" } }, [
                    _c("div", {
                      domProps: { textContent: _vm._s(_vm.form.typeName) },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "内容",
                        prop: "content",
                        rules: [
                          {
                            required: true,
                            message: "请填写内容",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        staticClass: "input-content",
                        attrs: { placeholder: "内容", maxlength: 300 },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.addressVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "address",
                            label: _vm.addressLabel,
                            rules: [
                              {
                                required: true,
                                message: "请填写" + _vm.addressLabel,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "input-content",
                            attrs: { placeholder: _vm.addressLabel },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.contactVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "contact",
                            label: "负责人姓名",
                            rules: [
                              {
                                required: true,
                                message: "请填写负责人姓名",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "input-content",
                            attrs: { placeholder: "负责人姓名" },
                            model: {
                              value: _vm.form.contact,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contact", $$v)
                              },
                              expression: "form.contact",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.contactVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "phone",
                            label: "联系电话",
                            rules: [
                              {
                                required: true,
                                message: "请填写联系电话",
                                trigger: "blur",
                              },
                              { validator: _vm.validatePhone, trigger: "blur" },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "input-content",
                            attrs: { placeholder: "联系电话" },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "关联设备" } }, [
                    _c("div", {
                      domProps: { textContent: _vm._s(_vm.form.count) },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }