// 通用类别
const typeOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '维保内容',
    value: 1
  },
  {
    text: '维保单位',
    value: 3
  },
  {
    text: '巡检内容',
    value: 4
  },
  {
    text: '抄表内容',
    value: 5
  }
]

export {
  typeOpts
}
