// 获取通用内容列表
const getCommonListURL = `${API_CONFIG.baseURL}smDeviceAction!commonContentList.action`
// 通用内容导出列表
const exportCommonListURL = `${API_CONFIG.baseURL}smDeviceAction!exportCommonContentToExcel.action`
const saveCommonURL = `${API_CONFIG.baseURL}smDeviceAction!saveCommonContent.action`
// 通用编辑保存
const editCommonURL = `${API_CONFIG.baseURL}smDeviceAction!updateCommonContent.action`
// 通用内容详情查询
const getCommonDetail = `${API_CONFIG.baseURL}smDeviceAction!queryCommonContent.action`

export {
  getCommonListURL,
  getCommonDetail,
  exportCommonListURL,
  saveCommonURL,
  editCommonURL
}
