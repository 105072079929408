<template>
  <div class="device-manager-common-edit-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="通用类别">
            <div v-text="form.typeName"></div>
          </el-form-item>
          <el-form-item label="内容" prop="content" :rules="[{ required: true, message: '请填写内容', trigger: 'blur'}]">
            <v-input class="input-content" placeholder="内容" :maxlength="300" v-model="form.content" />
          </el-form-item>
          <el-form-item prop="address" v-if="addressVisible" :label="addressLabel" :rules="[{ required: true, message: '请填写'+addressLabel, trigger: 'blur'}]">
            <v-input class="input-content" :placeholder="addressLabel" v-model="form.address" />
          </el-form-item>
          <el-form-item v-if="contactVisible" prop="contact" label="负责人姓名" :rules="[{ required: true, message: '请填写负责人姓名', trigger: 'blur'}]">
            <v-input class="input-content" placeholder="负责人姓名" v-model="form.contact" />
          </el-form-item>
          <el-form-item v-if="contactVisible" prop="phone" label="联系电话" :rules="[{ required: true, message: '请填写联系电话', trigger: 'blur'},{ validator: validatePhone, trigger: 'blur' }]">
            <v-input class="input-content" placeholder="联系电话" v-model="form.phone" />
          </el-form-item>
          <el-form-item label="关联设备">
            <div v-text="form.count"></div>
          </el-form-item>
          <el-form-item label="备注">
            <v-textarea v-model="form.remarks" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { typeOpts } from './map'
import { getCommonDetail, editCommonURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { mobileRegular, telephoneRegular } from 'common/regular'

export default {
  name: 'registerUserMgrForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      queryUrl: getCommonDetail,
      submitUrl: editCommonURL,
      form: {
        remarks: ''
      },
      typeOpts: typeOpts.filter(item => item.value)
    }
  },
  computed: {
    addressLabel () {
      let label = ''
      if (this.form.type === 3) {
        label = '单位地址'
      } else if (this.form.type === 5) {
        label = '单位'
      }
      return label
    },
    addressVisible () {
      return this.form.type === 3 || this.form.type === 5
    },
    contactVisible () {
      return this.form.type === 3
    }
  },
  mounted () {
    this.$refs.form.getData({ id: this.$route.query.id })
    this.$setBreadcrumb('编辑')
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      if (!regExp.test(value) && !connectionRegExp.test(value)) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    update (data) {
      let obj = {}
      for (let key in data) {
        if (data[key] !== undefined) {
          obj[key] = data[key]
        }
      }
      obj.remarks = obj.remarks || ''
      this.form = { ...obj }
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item {
    text-align: left;
  }
</style>
<style lang="scss">
.device-manager-common-edit-container {
  .input-content {
    .el-input {
      width: 300px !important;
    }
  }
}
</style>
